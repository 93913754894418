import React, { useContext, useEffect, useState } from "react";
import { teamsURL } from "../Hooks/URL";
import useFetch from "../Hooks/useFetch";
import useDelete from "../Hooks/useDelete";
import { useParams } from "react-router-dom";
import { userContext } from "../Contexts/userContext";
import useSave from "../Hooks/useSave";
import TeamsDetails from "../Templates/Home/Teams/TeamsDetails/TeamsDetails";

function TeamsDetailsPage() {
  const { contextData, setContextData } = useContext(userContext);
  let { id } = useParams();

  const url = `${teamsURL}/${id}`;

  const [team, setTeam] = useState({});
  const { data, loading, error } = useFetch(url);

  const [go, setGo] = useState(false);
  const { deleteRes } = useDelete(url, go, `teams`);

  useEffect(() => {
    if (!loading && !error && data) {
      setTeam(data.data);
    }
  }, [data, loading, error]);

  return (
    <TeamsDetails
      url={url}
      loading={loading}
      role={contextData?.role}
      team={team}
      setGo={setGo}
    />
  );
}

export default TeamsDetailsPage;
