import "./Transactions.css";
import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import PrimaryButton from "../../../Components/Atoms/Buttons/Primary/PrimaryButton";
import FormModal from "../../../Components/Molecules/FormModal/FormModal";
import { BarLoader } from "react-spinners";
import { BaseURL } from "../../../Hooks/URL";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import TextFieldInput from "../../../Components/Atoms/Inputs/TextField/TextFieldInput";
import { styled } from "@mui/material/styles";
import { images } from "../../../Assets/assetsHelper";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import Filter from "../../../Components/Molecules/Filter/Filter";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  "&:first-child": {
    borderRadius: "5px 0 0 0",
  },
  "&:last-child": {
    borderRadius: "0 5px 0 0",
  },
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#6a0505",
    color: theme.palette.common.white,
    fontSize: "15px",
  },
}));

const columns = [
  {
    id: "id",
    label: "Transaction ID",
    minWidth: 50,
  },
  {
    id: "account",
    label: "Account Code",
    minWidth: 100,
  },
  {
    id: "netAmountCents",
    label: "Net Amount",
    minWidth: 100,
  },
  {
    id: "totalAmountCents",
    label: "Total Amount",
    minWidth: 100,
  },
  {
    id: "paymentMethod",
    label: "Payment Method",
    minWidth: 100,
  },
  {
    id: "paymentGateway",
    label: "Payment Gateway",
    minWidth: 100,
  },
  { id: "createdAt", label: "Paid At", minWidth: 100 },
];

function Transactions({
  page,
  rowsPerPage,
  handleChangeRowsPerPage,
  searchData,
  search,
  setSearchData,
  setSearch,
  transactions,
  navigate,
  handleChangePage,
  loading,
  pageCount,
  totalSum,
}) {
  return (
    <div className="mainContainer">
      {transactions && !loading ? (
        <>
          <h3 style={{ fontSize: "20px", margin: "15px 0" }}>
            Total Gross: {(totalSum?.totalGrossAmountCents / 100).toFixed(2)}{" "}
            EGP
          </h3>
          <h3 style={{ fontSize: "20px", margin: "15px 0" }}>
            Total Amount: {(totalSum?.totalAmountCents / 100).toFixed(2)} EGP
          </h3>
        </>
      ) : (
        ""
      )}
      <div className="cont">
        <div className="header" style={{ justifyContent: "flex-start" }}>
          <TextFieldInput
            searchIcon={true}
            Required={false}
            type="text"
            placeholder={"Search"}
            className="SearchBar"
            name={"search"}
            change={(e) => setSearchData(e.target.value)}
            newValue={searchData}
            searchAction={() => {
              if (search != searchData) {
                setSearch(searchData);
              }
            }}
            keyDown={(e) => {
              if (e.keyCode === 13)
                if (search != searchData) {
                  setSearch(searchData);
                }
            }}
          />
        </div>
        <Paper
          className="tableCont"
          sx={{ width: "fit-content", minWidth: "100%" }}
        >
          {transactions && !loading ? (
            <>
              <TableContainer>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <StyledTableCell
                          key={column.id}
                          align={column.align}
                          style={{
                            minWidth: column.minWidth,
                          }}
                        >
                          {column.label}
                        </StyledTableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {transactions?.map((transaction) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={transaction.id}
                          style={{ cursor: "pointer" }}
                        >
                          {columns.map((column) => {
                            const value = transaction[column.id];
                            return (
                              <TableCell
                                key={column.id}
                                align={column.align}
                                style={{ padding: "10px" }}
                              >
                                {column.id == "account" ? (
                                  <span
                                    style={{ textDecoration: "underline" }}
                                    onClick={() =>
                                      navigate(`/accounts/${value?.id}`)
                                    }
                                  >
                                    {value?.code}
                                  </span>
                                ) : column.id == "netAmountCents" ||
                                  column.id == "totalAmountCents" ? (
                                  `${(value / 100).toFixed(2)} ${
                                    transaction?.currency?.code
                                  }`
                                ) : column.id == "createdAt" ? (
                                  `${new Date(
                                    value
                                  )?.toLocaleDateString()} / ${new Date(
                                    value
                                  )?.toLocaleTimeString()}`
                                ) : (
                                  value ?? "-"
                                )}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
                {!transactions?.length ? (
                  <h3 style={{ textAlign: "center", margin: "50px" }}>
                    No transactions Added Yet!!
                  </h3>
                ) : (
                  ""
                )}
              </TableContainer>
              {/* <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={pageCount * rowsPerPage}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              /> */}
            </>
          ) : (
            <div className="loadingBox">
              <BarLoader color="#6a0505" />
            </div>
          )}
        </Paper>
      </div>
    </div>
  );
}

export default Transactions;
