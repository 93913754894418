import { Box, Button } from "@mui/material";
import { images } from "../../../../Assets/assetsHelper";
import "./AccountsDetails.css";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { BarLoader } from "react-spinners";
import DeleteForm from "../../../../Components/Molecules/DeleteForm/DeleteForm";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { BaseURL } from "../../../../Hooks/URL";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import { PulseLoader } from "react-spinners";
import PrimaryButton from "../../../../Components/Atoms/Buttons/Primary/PrimaryButton";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: 8,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: 8,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function AccountsDetails({ account, loading, setGo }) {
  const navigate = useNavigate();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div className="mainContainer">
      {account && !loading ? (
        <div className="TeacherCont">
          <Box>
            <h2 style={{ fontSize: "30px", margin: "0 0 10px 0" }}>
              Account Code: {account?.account?.code} - {account?.account?.type}
            </h2>
            <Tabs
              className="tabs"
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Account Details" />
              <Tab label="Teams" />
              <Tab label="Payment Info" />
            </Tabs>
            <CustomTabPanel value={value} index={0}>
              <div className="greyBox">
                <div style={{ width: "100%" }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: { xs: "column", sm: "row" },
                    }}
                  >
                    {" "}
                    <Box
                      sx={{
                        display: "flex",
                      }}
                    >
                      {/* <a
                    href={`${BaseURL}/competetions/1/applications/${
                      application?.code
                    }/pdf?timeZone=${
                      Intl.DateTimeFormat().resolvedOptions().timeZone
                    }`}
                    target="_blank"
                  >
                    <PrimaryButton
                      classBtn={"center editButton"}
                      text={
                        <>
                          <DownloadForOfflineIcon
                            style={{
                              marginRight: "5px",
                              fontSize: "20px",
                              marginBottom: "3px",
                            }}
                          />
                          {"Download Summary PDF"}
                        </>
                      }
                      style={{
                        textTransform: "capitalize",
                        borderRadius: "5px",
                        color: "var(--text-secondary)",
                        backgroundColor: "var(--text-primary)",
                        height: "40px",
                        padding: "0 30px",
                        margin: "0 20px 0 0",
                      }}
                    />
                  </a> */}
                      {/* <DeleteForm
                        setGo={setGo}
                        title={"Are you sure to delete this Account ?"}
                      /> */}
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: { xs: "column", md: "row" },
                      width: "100%",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box sx={{ width: { xs: "100%", md: "50%" } }}>
                      <h3 style={{ fontSize: "20px", margin: "0 0 15px" }}>
                        Admin Details:
                      </h3>
                      <ul className="confirmList">
                        <li>
                          <span className="listTitle">Name:</span>{" "}
                          {account?.admin?.name}
                        </li>
                        <li>
                          <span className="listTitle">Email:</span>{" "}
                          {account?.admin?.email}
                        </li>
                        <li>
                          <span className="listTitle">Phone Number:</span>{" "}
                          {account?.admin?.contactNumber}
                        </li>
                        {account?.personalAccountInfo ? (
                          <>
                            <li>
                              <span className="listTitle">Country:</span>{" "}
                              {account?.personalAccountInfo?.country?.name}
                            </li>
                            <li>
                              <span className="listTitle">City:</span>{" "}
                              {account?.personalAccountInfo?.city?.name}
                            </li>
                          </>
                        ) : (
                          ""
                        )}
                      </ul>
                    </Box>
                    {account?.organization ? (
                      <Box sx={{ width: { xs: "100%", md: "50%" } }}>
                        <h3 style={{ fontSize: "20px", margin: "0 0 15px" }}>
                          Organization details:
                        </h3>
                        <ul className="confirmList">
                          <li>
                            <span className="listTitle">Name:</span>{" "}
                            {account?.organization?.name}
                          </li>
                          <li>
                            <span className="listTitle">Phone Number:</span>{" "}
                            {account?.organization?.branch?.contactNumber}
                          </li>
                          <li>
                            <span className="listTitle">Branch Country:</span>{" "}
                            {account?.organization?.branch?.country?.name}
                          </li>
                          <li>
                            <span className="listTitle">Branch City:</span>{" "}
                            {account?.organization?.branch?.city?.name}
                          </li>
                          <li>
                            <span className="listTitle">Address:</span>{" "}
                            {account?.organization?.branch?.address}
                          </li>
                        </ul>
                      </Box>
                    ) : (
                      ""
                    )}
                  </Box>
                </div>
              </div>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              {account?.teams?.length ? (
                <div className="teamsCont">
                  {account?.teams?.map((team) => (
                    <div className="teamBox">
                      <Box
                        sx={{ cursor: "pointer" }}
                        onClick={() => navigate(`/teams/${team?.id}`)}
                      >
                        <ul className="confirmList">
                          <li>
                            <span className="listTitle">Team Name:</span>{" "}
                            {team?.name}
                          </li>
                          <li>
                            <span className="listTitle">Competition:</span>{" "}
                            {team?.onlineParticipant
                              ? team?.offlineParticipant
                                ? "Both (online & offline)"
                                : "Online Competition"
                              : "Offline Competition"}
                          </li>
                          <li>
                            <span className="listTitle">Category:</span>{" "}
                            {team?.category?.name}
                          </li>
                          <li>
                            <span className="listTitle">Age Group:</span>{" "}
                            {team?.ageGroup?.name}
                          </li>
                          <li>
                            <span className="listTitle">Price:</span>{" "}
                            {(team?.price?.amountCents / 100).toFixed(2)}{" "}
                            {team?.price?.currency?.code}
                          </li>
                          <li>
                            <span className="listTitle">Coach Name:</span>{" "}
                            {team?.coach?.name}
                          </li>
                          <li>
                            <span className="listTitle">
                              Coach Phone Number:
                            </span>{" "}
                            {team?.coach?.contactNumber}
                          </li>
                          <li>
                            <span className="listTitle">Team Members:</span>
                          </li>
                        </ul>
                        <TableContainer
                          component={Paper}
                          sx={{
                            marginTop: "10px",
                          }}
                        >
                          <Table aria-label="customized table">
                            <TableHead>
                              <TableRow>
                                <StyledTableCell align="center">
                                  name
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  Birthday
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  Age
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  National ID
                                </StyledTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {team?.members?.map((stu) => (
                                <StyledTableRow key={stu.name}>
                                  <StyledTableCell align="center">
                                    {stu.name}
                                  </StyledTableCell>
                                  <StyledTableCell align="center">
                                    {stu?.birthday?.split("T")?.at(0)}
                                  </StyledTableCell>
                                  <StyledTableCell align="center">
                                    {stu?.age}
                                  </StyledTableCell>
                                  <StyledTableCell align="center">
                                    {stu?.nationalId}
                                  </StyledTableCell>
                                </StyledTableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Box>
                    </div>
                  ))}
                </div>
              ) : (
                <h3 style={{ textAlign: "center", margin: "50px" }}>
                  No Teams Added Yet!!
                </h3>
              )}
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <div className="greyBox" style={{ alignItems: "flex-start" }}>
                <Box sx={{ width: { xs: "100%", md: "50%" } }}>
                  <h3 style={{ fontSize: "20px", margin: "0 0 15px" }}>
                    Payment Details:
                  </h3>
                  <ul className="confirmList">
                    <li>
                      <span className="listTitle">Paid:</span>{" "}
                      {account?.paymentData?.details?.paid ? "Yes" : "Not Yet"}
                    </li>
                    <li>
                      <span className="listTitle">Can Pay:</span>{" "}
                      {account?.paymentData?.details?.paid ? "Yes" : "Not Yet"}
                    </li>
                    <li>
                      <span className="listTitle">Payment Status:</span>{" "}
                      {account?.paymentData?.details?.paymentStatus ?? "-"}
                    </li>
                    <li>
                      <span className="listTitle">Amount To Pay:</span>{" "}
                      {account?.paymentData?.details?.amountToPay?.amountCents
                        ? ` ${
                            account?.paymentData?.details?.amountToPay
                              ?.amountCents / (100).toFixed(2)
                          } ${
                            account?.paymentData?.details?.amountToPay?.currency
                              ?.code
                          }`
                        : "-"}
                    </li>
                    <li>
                      <span className="listTitle">Amount To Refund:</span>{" "}
                      {account?.paymentData?.details?.amountToRefund
                        ?.amountCents
                        ? ` ${
                            account?.paymentData?.details?.amountToRefund
                              ?.amountCents / (100).toFixed(2)
                          } ${
                            account?.paymentData?.details?.amountToRefund
                              ?.currency?.code
                          }`
                        : "-"}
                    </li>
                  </ul>
                </Box>
                <Box sx={{ width: { xs: "100%", md: "50%" } }}>
                  <h3 style={{ fontSize: "20px", margin: "0 0 15px" }}>
                    Account Price:
                  </h3>
                  <ul className="confirmList">
                    <li>
                      <span className="listTitle">Total Price:</span>{" "}
                      {account?.paymentData?.accountPrice?.totalPriceAmountCents
                        ?.amountCents
                        ? `${
                            account?.paymentData?.accountPrice
                              ?.totalPriceAmountCents?.amountCents /
                            (100).toFixed(2)
                          } ${
                            account?.paymentData?.accountPrice?.currency?.code
                          }`
                        : "-"}
                    </li>
                    <li>
                      <span className="listTitle">Discount Percentage:</span>{" "}
                      {account?.paymentData?.accountPrice?.discount
                        ?.percentage ?? "-"}
                    </li>
                    <li>
                      <span className="listTitle">Discount Amount:</span>{" "}
                      {account?.paymentData?.accountPrice?.discount?.amountCents
                        ? ` ${
                            account?.paymentData?.accountPrice?.discount
                              ?.amountCents / (100).toFixed(2)
                          } ${
                            account?.paymentData?.accountPrice?.currency?.code
                          }`
                        : "-"}
                    </li>
                    <li>
                      <span className="listTitle">
                        Total Price After Discount:
                      </span>{" "}
                      {account?.paymentData?.accountPrice?.discount
                        ?.totalPriceAmountCentsAfterDiscount
                        ? ` ${
                            account?.paymentData?.accountPrice?.discount
                              ?.totalPriceAmountCentsAfterDiscount /
                            (100).toFixed(2)
                          } ${
                            account?.paymentData?.accountPrice?.currency?.code
                          }`
                        : "-"}
                    </li>
                    <li>
                      <span className="listTitle">
                        Total Transaction Net Amount:
                      </span>{" "}
                      {account?.paymentData?.totalTransactionsNetAmount
                        ?.amountCents
                        ? ` ${
                            account?.paymentData?.totalTransactionsNetAmount
                              ?.amountCents / (100).toFixed(2)
                          } ${
                            account?.paymentData?.totalTransactionsNetAmount
                              ?.currency?.code
                          }`
                        : "-"}
                    </li>
                  </ul>
                </Box>
                <Box sx={{ width: "100%" }}>
                  <h3 style={{ fontSize: "20px", margin: "15px 0 10px" }}>
                    Transactions History:{" "}
                    {!account?.paymentData?.paymentTransactions?.length ? (
                      <span
                        style={{
                          fontSize: "15px",
                          fontWeight: "400",
                          marginLeft: "5px",
                        }}
                      >
                        No Transactions Yet..
                      </span>
                    ) : (
                      ""
                    )}
                  </h3>
                  <TableContainer
                    component={Paper}
                    sx={{
                      marginTop: "10px",
                      width: { xs: "100%", md: "60%" },
                    }}
                  >
                    <Table aria-label="customized table">
                      <TableHead>
                        <TableRow>
                          <StyledTableCell align="center">ID</StyledTableCell>
                          <StyledTableCell align="center">Code</StyledTableCell>
                          <StyledTableCell align="center">
                            Method
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            Date/Time
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            Net Amount
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            Tax Amount
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            Total
                          </StyledTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {account?.paymentData?.paymentTransactions?.length
                          ? account?.paymentData?.paymentTransactions?.map(
                              (transaction) => (
                                <StyledTableRow key={transaction.id}>
                                  <StyledTableCell align="center">
                                    {transaction.id ?? "-"}
                                  </StyledTableCell>
                                  <StyledTableCell align="center">
                                    {transaction.code ?? "-"}
                                  </StyledTableCell>
                                  <StyledTableCell align="center">
                                    {transaction.paymentMethod ?? "-"}
                                  </StyledTableCell>
                                  <StyledTableCell align="center">
                                    {transaction.createdAt
                                      ? new Date(
                                          transaction.createdAt
                                        )?.toLocaleTimeString()
                                      : ""}
                                  </StyledTableCell>
                                  <StyledTableCell align="center">
                                    {transaction?.netAmountCents}{" "}
                                    {transaction?.currency?.code}
                                  </StyledTableCell>
                                  <StyledTableCell align="center">
                                    {transaction?.taxAmountCents}{" "}
                                    {transaction?.currency?.code}
                                  </StyledTableCell>
                                  <StyledTableCell align="center">
                                    {transaction?.totalAmountCents}{" "}
                                    {transaction?.currency?.code}
                                  </StyledTableCell>
                                </StyledTableRow>
                              )
                            )
                          : ""}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </div>
            </CustomTabPanel>
          </Box>
        </div>
      ) : (
        <div className="loadingBox">
          <BarLoader color="#6a0505" />
        </div>
      )}
    </div>
  );
}

export default AccountsDetails;
