// export const BaseURL = `https://api.codechallenge-championship.org`;
export const BaseURL = `https://apistaging.codechallenge-championship.org`;

export const loginURL = `${BaseURL}/admin/dashboard/login`;
export const logoutURL = `${BaseURL}/admin/dashboard/logout`;
export const userInfo = `${BaseURL}/admin/dashboard/loggedInInfo`;
export const accountsURL = `${BaseURL}/admin/dashboard/accounts`;
export const categoriesURL = `${BaseURL}/admin/dashboard/categories`;
export const organizationsURL = `${BaseURL}/admin/dashboard/organizations`;
export const membersURL = `${BaseURL}/admin/dashboard/members`;
export const coachesURL = `${BaseURL}/admin/dashboard/coaches`;
export const teamsURL = `${BaseURL}/admin/dashboard/teams`;
export const transactionsURL = `${BaseURL}/admin/dashboard/payment/transactions`;
export const teamsExcelURL = `${BaseURL}/admin/dashboard/teams/excel`;
// export const dashboardURL = `${BaseURL}/dashboard`;
export const activateURL = `${BaseURL}/activate`;
export const dashboardURL = `${BaseURL}/dashboard`;
