import "./Teams.css";
import React, { useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import PrimaryButton from "../../../Components/Atoms/Buttons/Primary/PrimaryButton";
import FormModal from "../../../Components/Molecules/FormModal/FormModal";
import { BarLoader } from "react-spinners";
import { BaseURL } from "../../../Hooks/URL";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import TextFieldInput from "../../../Components/Atoms/Inputs/TextField/TextFieldInput";
import { styled } from "@mui/material/styles";
import { images } from "../../../Assets/assetsHelper";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import Filter from "../../../Components/Molecules/Filter/Filter";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import axios from "axios";
import { useCookies } from "react-cookie";
import { Box } from "@mui/material";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  "&:first-child": {
    borderRadius: "5px 0 0 0",
  },
  "&:last-child": {
    borderRadius: "0 5px 0 0",
  },
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#6a0505",
    color: theme.palette.common.white,
    fontSize: "15px",
  },
}));

const columns = [
  {
    id: "id",
    label: "ID",
    minWidth: 50,
  },
  {
    id: "name",
    label: "Team Name",
    minWidth: 100,
  },
  {
    id: "code",
    label: "Team Code",
    minWidth: 100,
  },
  {
    id: "onlineParticipant",
    label: "Online",
    minWidth: 100,
  },
  {
    id: "offlineParticipant",
    label: "Offline",
    minWidth: 100,
  },
  {
    id: "category",
    label: "Category",
    minWidth: 100,
  },
  { id: "ageGroup", label: "Age Group", minWidth: 100 },
];

function Teams({
  page,
  rowsPerPage,
  handleChangeRowsPerPage,
  searchData,
  search,
  setSearchData,
  setSearch,
  teams,
  navigate,
  handleChangePage,
  loading,
  pageCount,
  setFilter,
  filter,
}) {
  const [cookies, setCookie] = useCookies(["TOKEN"]);
  return (
    <div className="mainContainer">
      <div className="cont">
        <div className="header">
          <Box sx={{ display: "flex" }}>
            <TextFieldInput
              searchIcon={true}
              Required={false}
              type="text"
              placeholder={"Search"}
              className="SearchBar"
              name={"search"}
              change={(e) => setSearchData(e.target.value)}
              newValue={searchData}
              searchAction={() => {
                if (search != searchData) {
                  setSearch(searchData);
                }
              }}
              keyDown={(e) => {
                if (e.keyCode === 13)
                  if (search != searchData) {
                    setSearch(searchData);
                  }
              }}
            />
            <Filter ageGroup categoryGroup paiedApp setFilter={setFilter} />
          </Box>
          {/* <PrimaryButton
            classBtn={"center editButton"}
            click={() =>
              axios({
                url: `${BaseURL}/dashboard/teams/excel?${
                  filter ? `${filter}` : ""
                }${search ? `&search=${search}` : ""}`,
                method: "GET",
                responseType: "blob", // important
                headers: {
                  "Content-Type": "application/json",
                  Accept: "application/app.js",
                  Authorization: `Bearer ${cookies.TOKEN}`,
                },
              }).then((response) => {
                const href = URL.createObjectURL(response.data);
                console.log(response?.headers["file-name"]);
                const link = document.createElement("a");
                link.href = href;
                link.setAttribute("download", response?.headers["file-name"]); //or any other extension
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
              })
            }
            text={
              <>
                <DownloadForOfflineIcon
                  style={{
                    marginRight: "5px",
                    fontSize: "20px",
                    marginBottom: "3px",
                  }}
                />
                {"Download Teams Excel"}
              </>
            }
            style={{
              textTransform: "capitalize",
              borderRadius: "5px",
              color: "var(--text-secondary)",
              backgroundColor: "var(--text-primary)",
              height: "50px",
              padding: "0 30px",
              margin: "0 0 0 20px",
            }}
          /> */}
        </div>
        <Paper
          className="tableCont"
          sx={{ width: "fit-content", minWidth: "100%" }}
        >
          {teams && !loading ? (
            <>
              <TableContainer>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <StyledTableCell
                          key={column.id}
                          align={column.align}
                          style={{
                            minWidth: column.minWidth,
                          }}
                        >
                          {column.label}
                        </StyledTableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {teams?.map((team) => {
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          tabIndex={-1}
                          key={team.id}
                          onClick={() => {
                            navigate(`/teams/${team.id}`);
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          {columns.map((column) => {
                            const value = team[column.id];
                            return (
                              <TableCell
                                key={column.id}
                                align={column.align}
                                style={{ padding: "10px" }}
                              >
                                {column.id === "onlineParticipant" ||
                                column.id === "offlineParticipant" ? (
                                  value ? (
                                    <DoneIcon className="activatedTick" />
                                  ) : (
                                    <CloseIcon className="NonActivatedTick" />
                                  )
                                ) : column.id == "category" ||
                                  column.id == "ageGroup" ? (
                                  value?.name
                                ) : (
                                  value ?? "-"
                                )}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
                {!teams?.length ? (
                  <h3 style={{ textAlign: "center", margin: "50px" }}>
                    No teams Added Yet!!
                  </h3>
                ) : (
                  ""
                )}
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 100]}
                component="div"
                count={pageCount * rowsPerPage}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
          ) : (
            <div className="loadingBox">
              <BarLoader color="#6a0505" />
            </div>
          )}
        </Paper>
      </div>
    </div>
  );
}

export default Teams;
