import axios from "axios";
import { useEffect, useReducer, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { CookiesProvider, useCookies } from "react-cookie";

const initialState = {
  dataRes: [],
  loadingRes: true,
  errorRes: null,
};

const ACTION = {
  API_REQUEST: "api-request",
  FETCH_DATA: "fetch-data",
  ERROR: "error",
};

function reducer(saveState, { type, payload }) {
  switch (type) {
    case ACTION.API_REQUEST:
      return { ...saveState, loadingRes: true, errorRes: null };
    case ACTION.FETCH_DATA:
      return { ...saveState, dataRes: payload, loadingRes: false };
    case ACTION.ERROR:
      return { ...saveState, loadingRes: false, errorRes: payload };
    default:
      return saveState;
  }
}
function useLogout({ url, goSave, setGoSave }) {
  const navigate = useNavigate();
  // const [cookies, setCookie, removeCookie] = useCookies(["TOKEN"]);
  const [saveState, dispatch] = useReducer(reducer, initialState);
  useEffect(() => {
    if (goSave) {
      dispatch({ type: ACTION.API_REQUEST });
      axios
        .post(url, "", {
          withCredentials: true,
          headers: {
            Accept: "application/app.js",
          },
        })
        .then((res) => {
          dispatch({ type: ACTION.FETCH_DATA, payload: res.data });
          // removeCookie("TOKEN");
          toast.success("Logged out Successfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          navigate("/login");
          localStorage.setItem("isLogged", false);
        })
        .catch((err) => {
          dispatch({ type: ACTION.ERROR, payload: err?.response });
          toast.error(err?.response?.data?.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnFocusLoss: false,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        });
      setGoSave(false);
    }
  }, [goSave]);
  return { saveState };
}

export default useLogout;
